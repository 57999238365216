<template>
    <v-app>
        <div class="d-flex megaMenuColumnClass">
            <div class="mr-6" v-for="(megaMenuItem, index) of megaMenuItems" :key="index">
                <div class="topDivClass">
                    <div class=" d-flex flex-column">
                        <span class="columnFontClass flexGrowNone">Column {{ index + 1 }}</span>
                        <span class="mainMenuFontClass flexGrowNone">Column {{ index + 1 }}</span>
                    </div>
                    <div class=" d-flex flex-row">
                        <img class="mr-2" :src="whiteEditIcon" @click="editMainMenu()"/>
                        <img class="mr-2" :src="whiteDeleteIcon" @click="deleteMainMenu()"/>
                    </div>
                </div>
                <div class="bottomDivClass">

                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    name: 'MegaMenuComponent',
    props: [],
    data() {
        return {
            cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
            megaMenuItems: [1,2,3,4],
            whiteEditIcon: 'whiteEditIcon.svg',
            whiteDeleteIcon: 'whiteDeleteIcon.svg',
            editIcon: 'editIcon.svg',
            deleteIcon: 'deleteIcon.svg',
        }
    },
    setup() {
        
    },
    mounted() {
        this.whiteEditIcon = this.cloudFront + this.whiteEditIcon;
        this.whiteDeleteIcon = this.cloudFront + this.whiteDeleteIcon;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
    },
}
</script>
<style scoped>
.megaMenuColumnClass {
    align-items: flex-start;
    padding: 0px;
    width: 357.75px;
    height: 741px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
}
.topDivClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    width: 357.75px;
    height: 62px;
    background: #48ACEF;
    border: 1px solid #48ACEF;
    border-radius: 8px 8px 0px 0px;
    order: 0;
    align-self: stretch;
}
.bottomDivClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    width: 357.75px;
    height: 679px;
    background: #FFFFFF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 8px 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}
.columnFontClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    order: 0;
}
.mainMenuFontClass {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    order: 1;
}
</style>